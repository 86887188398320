import gql from 'graphql-tag';

export const QUERIES = {
  /** Manga queries */
  SCRAPERS: gql`
    query FETCH_SCRAPERS {
      scrapersList {
        id
        name
        alias
      }
    }
  `,
  /** Chapter queries */
  MANGA_SCRAPERS: gql`
    query FETCH_MANGA_SCRAPERS($mangaId: String, $limit: Int, $page: Int) {
      mangaScrapersList(mangaId: $mangaId, limit: $limit, page: $page) {
        mangaRelations {
          id
          scraper {
            id
            name
            alias
            linkFormat
          }
          siteMangaId
          lastScrapedChapter
          lastSuccessfulScrap
          totalChaptersScraped
          cronjobString
          enabled
          running
        }
        currentPage
        pages
        total
      }
    }
  `,
  ALL_MANGA_SCRAPERS: gql`
    query FETCH_ALL_MANGA_SCRAPERS($limit: Int, $page: Int) {
      allMangaScrapersList(limit: $limit, page: $page) {
        mangaRelations {
          id
          scraper {
            id
            name
            alias
            linkFormat
          }
          manga {
            id
            title
          }
          siteMangaId
          cronjobString
          enabled
          lastScrapedChapter
          totalChaptersScraped
          running
        }
        currentPage
        pages
        total
      }
    }
  `,
};

export const MUTATIONS = {
  /** Manga mutations */
  CREATE_MANGA_SCRAPER: gql`
    mutation CREATE_MANGA_SCRAPER(
      $scraperId: ID!
      $mangaId: ID!
      $siteMangaId: String!
      $cronjobString: String!
      $enabled: Boolean
    ) {
      createMangaScraperRelation(
        mangaScraperRelationData: {
          scraperId: $scraperId
          mangaId: $mangaId
          siteMangaId: $siteMangaId
          cronjobString: $cronjobString
          enabled: $enabled
        }
      ) {
        mangaScraperRelation {
          id
          scraper {
            id
            name
            alias
            linkFormat
          }
          siteMangaId
          cronjobString
          enabled
          running
        }
      }
    }
  `,
  UPDATE_MANGA_SCRAPER: gql`
    mutation UPDATE_MANGA_SCRAPER(
      $id: ID!
      $scraperId: ID!
      $siteMangaId: String!
      $cronjobString: String!
      $enabled: Boolean
    ) {
      updateMangaScraperRelation(
        mangaScraperRelationData: {
          id: $id
          scraperId: $scraperId
          siteMangaId: $siteMangaId
          cronjobString: $cronjobString
          enabled: $enabled
        }
      ) {
        mangaScraperRelation {
          id
          scraper {
            id
            name
            alias
            linkFormat
          }
          siteMangaId
          cronjobString
          enabled
          running
        }
      }
    }
  `,
  DELETE_MANGA_SCRAPER: gql`
    mutation DELETE_MANGA_SCRAPER($mangaScraperId: ID!) {
      deleteMangaScraperRelation(mangaScraperId: $mangaScraperId) {
        response
      }
    }
  `,
  START_MANGA_SCRAPER: gql`
    mutation START_MANGA_SCRAPER($mangaScraperId: ID!, $chapterIndex: Int) {
      startMangaScraper(
        mangaScraperId: $mangaScraperId
        chapterIndex: $chapterIndex
      ) {
        response
      }
    }
  `,
  ABORT_MANGA_SCRAPER: gql`
    mutation ABORT_MANGA_SCRAPER($mangaScraperId: ID!) {
      abortMangaScraper(mangaScraperId: $mangaScraperId) {
        response
      }
    }
  `,
};
