import { QUERIES, MUTATIONS } from '../schemas/mangaScraper';
import { apolloClient } from '../index';

export const fetchScrapers = async () => {
  const { data } = await apolloClient.query({
    query: QUERIES.SCRAPERS,
  });
  return data;
};

export const abortScrapingTask = async taskId => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.ABORT_SCRAPING_TASK,
    variables: { scrapingTaskId: taskId },
  });
  return data;
};

export const fetchAllMangaScrapers = async (limit = 100, page = 1) => {
  const { data } = await apolloClient.query({
    query: QUERIES.ALL_MANGA_SCRAPERS,
    variables: { limit, page },
  });
  return data;
};

export const fetchMangaScrapers = async (mangaId, limit = 100, page = 1) => {
  const { data } = await apolloClient.query({
    query: QUERIES.MANGA_SCRAPERS,
    variables: { mangaId, limit, page },
  });
  return data;
};

export const createMangaScraper = async mangaScraper => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.CREATE_MANGA_SCRAPER,
    variables: { ...mangaScraper },
  });
  return data;
};

export const startMangaScraper = async (id, chapterIndex) => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.START_MANGA_SCRAPER,
    variables: { mangaScraperId: id, chapterIndex },
  });
  return data;
};

export const abortMangaScraper = async id => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.ABORT_MANGA_SCRAPER,
    variables: { mangaScraperId: id },
  });
  return data;
};

export const updateMangaScraper = async (id, mangaScraper) => {
  const variables = { ...mangaScraper, id };
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.UPDATE_MANGA_SCRAPER,
    variables,
  });
  return data;
};

export const deleteMangaScraper = async id => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.DELETE_MANGA_SCRAPER,
    variables: { mangaScraperId: id },
  });
  return data;
};
