<template>
  <div class="node-info">
    <va-card :title="title">
      <template slot="default">
        <table class="node-info-table">
          <tr v-for="key of Object.keys(task)">
            <td>{{ TABLE_COLUMNS[key] }}</td>
            <td v-if="key === 'args'">
              <va-popover title="Extended value" :message="task[key]" placement="left">
                {{ getValueFromKey(key) }}
              </va-popover>
            </td>
            <td v-else>
              {{ getValueFromKey(key) }}
            </td>
          </tr>
        </table>
      </template>
      <va-button outline color="danger" @click="showAbortCeleryTaskModal = true">Abort task</va-button>
    </va-card>
    <va-modal v-model="showAbortCeleryTaskModal" size="small" title="Abort celery task?" :okText="$t('modal.confirm')"
      :cancelText="$t('modal.cancel')" @ok="abortTask">
      Are you sure you want to abort {{ task.id }} task?
    </va-modal>
  </div>
</template>

<script>
import { momentDaysAgo, momentDaysAgoFromSeconds } from '../../mixins/filters';
import { abortCeleryTask } from '../../apollo/api/scrapingTasks';

const MAX_STRING_LENGTH_FOR_TABLE_COLUMN = 50;

const TABLE_COLUMNS = {
  "id": "ID",
  "name": "Name",
  "args": "Args",
  "queue": "Queue",
  "status": "Status",
  "progress": "Progress",
  "childGroups": "Child Groups",
  "childTasks": "Child Tasks",
  "createdAt": "Created At",
  "lastModifiedAt": "Last Modified At",
  "dateDone": "Date done",
  "runningSince": "Running Since",
}

export default {
  name: "NodeInfo",
  props: {
    task: {
      type: Object,
      readonly: true
    }
  },
  data() {
    return {
      TABLE_COLUMNS,
      apiLoading: false,
      showAbortCeleryTaskModal: false
    }
  },
  computed: {
    isGroup() {
      console.log("Task: ", this.task)
      return this.task.id.startsWith("group")
    },
    title() {
      return this.isGroup ? "Group Details" : "Task Details";
    }
  },
  methods: {
    getValueFromKey(key) {
      if (key === "childGroups" || key === "childTasks") {
        return this.task[key].length;
      }

      if (key === "dateDone" || key === "createdAt" || key === "lastModifiedAt") {
        if (!this.task[key]) return "NA";
        return momentDaysAgo(this.task[key]);
      }

      if (key === "runningSince") {
        return momentDaysAgoFromSeconds(this.task[key]);
      }

      if (key === "args") {
        let argString = this.task[key];

        if (argString.length > MAX_STRING_LENGTH_FOR_TABLE_COLUMN)
          argString = argString.slice(0, MAX_STRING_LENGTH_FOR_TABLE_COLUMN) + "...";
        return argString;
      }

      return this.task[key];
    },
    async abortTask() {
      this.apiLoading = true;
      try {
        await abortCeleryTask(this.task.id);
        this.showToast('Celery task aborted successfully');
        this.$emit('delete', this.task.id);
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.apiLoading = false;
    }
  }
}
</script>

<style lang="scss">
.node-info {
  padding: 8px;
}

.node-info-table tr {
  padding: 4px;
}

.node-info-table tr td {
  padding: 4px;
  border: 1px solid #c9cacf;
}
</style>