const TASK_ICONS = {
  ROOT: 'fa fa-address-book',
  GROUP: 'fa fa-tasks',
  CHILD: 'fa fa-columns',
  RUNNING: 'fa fa-spinner',
  DONE: 'fa fa-check',
};

export const getNodeFromCeleryTask = (celeryTask, taskType) => {
  console.log('Task for celery: ', celeryTask);
  const taskNode = {
    text: celeryTask.id,
    icon: TASK_ICONS.ROOT,
    children: [],
    opened: false,
    data: celeryTask,
    isTask: true,
  };

  // Condition if task id turns out to be group
  if (taskType === 'GROUP') {
    taskNode.text = celeryTask;
    taskNode.icon = TASK_ICONS.GROUP;
    taskNode.children = [{
      text: 'Loading...',
      icon: TASK_ICONS.RUNNING
    }];
    taskNode.isTask = false;
  }

  if (taskType === "FLAT_CHILD") {
    taskNode.text = celeryTask;
    taskNode.icon = TASK_ICONS.CHILD;
    taskNode.isTask = true;
  } else if (taskType === "OBJECT_CHILD") {
    taskNode.text = celeryTask.id;
    taskNode.icon = TASK_ICONS.CHILD;
    taskNode.isTask = true;
  }

  // TODO: Do something abot child tasks having child groups. I'm already gettign tried of this nested relations
  // taskNode.children = [{
  //   text: 'Loading...',
  //   icon: TASK_ICONS.RUNNING
  // }];


  return taskNode;
};

export const isGroupTask = (taskNode) => {
  if (!!taskNode.data) {
    if (typeof taskNode.data === "string" && taskNode.data.startsWith("group")) return true;
    if (typeof taskNode.data === "object" && !!taskNode.data.isTask && taskNode.data.isTask === false) return true;
  }

  return false;
};

export default {
  getNodeFromCeleryTask,
  isGroupTask
};
