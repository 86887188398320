<template>
  <div class="scraper-task-row-action">
    <va-popover message="View task progress" placement="left">
      <a @click="emitViewTask">
        <va-icon
          :name="!isChildrenTreeVisible ? 'fa fa-eye' : 'fa fa-eye-slash'"
          :size="25"
          class="action-icon pointer"
        />
      </a>
    </va-popover>
    <va-popover
      message="Abort running tasks"
      placement="left"
      v-if="isTaskAbortable"
    >
      <a @click="showAbortScrapingTaskModal = true">
        <va-icon name="fa fa-stop-circle" color="red" :size="32" />
      </a>
    </va-popover>

    <va-modal
      v-model="showAbortScrapingTaskModal"
      size="small"
      :title="`Abort scraping task ${task.celeryTaskId}`"
      :okText="$t('modal.confirm')"
      :cancelText="$t('modal.cancel')"
      @ok="abortScrapingTaskMethod"
    >
      Are you sure you want to abort {{ task.celeryTaskId }} task?
    </va-modal>
  </div>
</template>

<script>
import { abortScrapingTask } from '../../../apollo/api/scrapingTasks';

export default {
  name: 'scraper-task-row-action',
  props: {
    task: Object,
    isChildrenTreeVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAbortScrapingTaskModal: false,
      apiLoading: false,
    };
  },
  computed: {
    isTaskAbortable() {
      const abortableTaskStatuses = [
        'PENDING',
        'RETRYING',
        'STARTED',
        'RUNNING',
        'RECEIVED',
      ];
      return (
        abortableTaskStatuses.findIndex(item => item === this.task.status) >= 0
      );
    },
  },
  methods: {
    emitViewTask() {
      this.$emit('view-task', this.task.id);
    },
    async abortScrapingTaskMethod() {
      this.apiLoading = true;
      try {
        await abortScrapingTask(this.task.id);
        this.showToast('Task aborted successfully');
        this.$emit('delete', this.task.id);
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.apiLoading = false;
    },
  },
};
</script>

<style>
.scraper-task-row-action {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
</style>
