import { QUERIES, MUTATIONS } from '../schemas/scrapingTasks';
import { apolloClient } from '../index';

export const fetchScrapingTasks = async (
  mangaScraperId,
  limit = 100,
  page = 1
) => {
  const { data } = await apolloClient.query({
    query: QUERIES.SCRAPING_TASKS,
    variables: { mangaScraperId, limit, page },
  });
  return data;
};

export const fetchCeleryTask = async taskId => {
  const { data } = await apolloClient.query({
    query: QUERIES.CELERY_TASK,
    variables: { celeryTaskId: taskId },
  });
  return data;
};

export const fetchCeleryGroup = async groupId => {
  const { data } = await apolloClient.query({
    query: QUERIES.CELERY_GROUP,
    variables: { celeryGroupId: groupId },
  });
  return data;
};

export const abortScrapingTask = async taskId => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.ABORT_SCRAPING_TASK,
    variables: { scrapingTaskId: taskId },
  });
  return data;
};

export const abortCeleryTask = async taskId => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.ABORT_CELERY_TASK,
    variables: { celeryTaskId: taskId },
  });
  return data;
}