<template>
  <div class="children-tree-container" v-show="celeryTask">
    <tree-view ref="treeView" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import TreeView from '../../../TreeView';
import { fetchCeleryTask } from '../../../../apollo/api/scrapingTasks';

export default {
  name: 'scraping-row-children',
  components: {
    TreeView,
  },
  props: {
    rowData: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
    },
    options: {
      type: Object,
    },
  },
  data() {
    return {
      id: null,
      celeryTask: null,
      scrapingTaskId: null,
    };
  },
  created() {
    this.id = this.rowData.celeryTaskId;
    this.scrapingTaskId = this.rowData.id;
  },
  async mounted() {
    this.celeryTask = await this.loadChildTaskFromCeleryTask(this.id);
    if (this.celeryTask) {
      const rootNode = this.$refs.treeView.addRootTask(this.celeryTask);

      for (const childGroup of this.celeryTask.childGroups) {
        this.$refs.treeView.addChildGroupTask(rootNode, childGroup);
      }

      for (const childTask of this.celeryTask.childTasks) {
        this.$refs.treeView.addChildTask(rootNode, childTask);
      }
    }
  },
  methods: {
    ...mapMutations(['setBackgroundLoading']),
    async loadChildTaskFromCeleryTask(celeryTaskId) {
      this.setBackgroundLoading(true);
      try {
        const { celeryTaskItem } = await fetchCeleryTask(celeryTaskId);
        if (!celeryTaskItem) {
          this.showToast('Failed to load task from celery backend', {
            position: 'top-right',
            duration: 1200,
            fullWidth: false,
          });
          return null;
        }
        return celeryTaskItem;
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.setBackgroundLoading(false);
    },
  },
};
</script>

<style lang="scss">
.children-tree-container {
  border: 1px solid #e9e9eb;
  border-radius: 5px;
}
</style>
