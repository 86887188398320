<template>
  <div class="tree-container">
    <div class="tree-chart-container">
      <v-jstree ref="vueTree" :data="rootNode" size="large" :show-checkbox="false" :multiple="false" :allow-batch="false"
        whole-row @item-click="itemClick" @item-toggle="taskRowToggle">
        <template slot-scope="_">
          <div style="display: inherit; width: 200px" @click.ctrl="ctrlClicked"
            @click.exact="exactClicked(_.vm, _.model, $event)">
            <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i>
            {{ _.model.text }}
          </div>
        </template></v-jstree>
    </div>
    <node-info class="node-info-container" :task="selectedNode.data" v-if="selectedNode" />
  </div>
</template>

<script>
import treeUtils from './utils';
import NodeInfo from './NodeInfo.vue';
import { fetchCeleryTask, fetchCeleryGroup } from '../../apollo/api/scrapingTasks';

export default {
  name: 'TreeView',
  components: {
    NodeInfo
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rootNode: [],
      selectedNode: null,
    };
  },
  methods: {
    ctrlClicked(event) {
      console.log('COntrol clicked item: ', event);
    },
    async exactClicked(node, item, event) {
      // console.log("Exact item clicked: ", item)
      // Don't know what's the diff between exact click and regular click
      await this.loadNode(item);
      if (this.selectedNode) {
        node.model = this.selectedNode;
      }
    },
    itemClick(node) {
      // console.log("Regular item clicked")
      // this.loadNode(node);
    },
    async loadNode(nodeItem) {
      this.selectedNode = null;
      if (!!nodeItem.data && typeof nodeItem.data !== "string") {
        this.selectedNode = nodeItem;
      } else if (!!nodeItem.data && typeof nodeItem.data === "string") {
        if (nodeItem.data.startsWith("group")) {
          // It's a group task
          const { celeryGroupItem } = await fetchCeleryGroup(nodeItem.data);
          if (celeryGroupItem) {
            // Load the result in 'data' for taskNode
            nodeItem.data = celeryGroupItem;
            // Reset the loading task
            nodeItem.children = [];
            // Load children using for-of loop
            for (const childTask of celeryGroupItem.childTasks) {
              this.addChildTask(nodeItem, childTask);
            }

            // TODO: Check for child group task as well
          }
        } else {
          const { celeryTaskItem } = await fetchCeleryTask(nodeItem.data);
          if (celeryTaskItem) {
            nodeItem = treeUtils.getNodeFromCeleryTask(celeryTaskItem, 'OBJECT_CHILD');
            nodeItem.children = [];
            // Load children using for-of loop
            for (const childTask of celeryTaskItem.childTasks) {
              this.addChildTask(nodeItem, childTask);
            }

            // Load groups
            for (const childGroup of celeryTaskItem.childGroups) {
              this.addChildGroupTask(nodeItem, childGroup);
            }
            this.selectedNode = nodeItem;
          }
        }
      }
    },
    async taskRowToggle(node, taskNode) {
      // TODO: Function to autoload child tasks but not using it any more
      // if (taskNode != this.rootNode[0] && taskNode.opened === true) {
      //   // Only do toggling and API calling for child tasks and groups
      //   // Make api calls for string objects only
      //   if (treeUtils.isGroupTask(taskNode)) {
      //     // If it's a group task
      //     const { celeryGroupItem } = await fetchCeleryGroup(taskNode.data);
      //     if (celeryGroupItem) {

      //       // Load the result in 'data' for taskNode
      //       taskNode.data = celeryGroupItem;
      //       // Reset the loading task
      //       taskNode.children = [];
      //       // Load children using for-of loop
      //       for (const childTask of celeryGroupItem.childTasks) {
      //         this.addChildTask(taskNode, childTask);
      //       }
      //     }
      //   } else {
      //     // Child task is toggled
      //     console.log("Child task is toggled: ", taskNode);
      //     // const celeryTaskItem = await fetchCeleryTask(celeryId);
      //     // if (celeryTaskItem) {
      //     //   // TODO: Do something for child task
      //     //   console.log("Celery task data: ", celeryTaskItem);
      //     // }
      //   }
      // }
    },
    addRootTask(taskData) {
      this.rootNode = [];
      const taskNode = treeUtils.getNodeFromCeleryTask(taskData, 'ROOT');
      this.rootNode.push(taskNode);
      return this.rootNode[0];
    },
    addChildGroupTask(currentNode, groupTask) {
      let childrenTaskNode = currentNode.children.find(
        child => child.text === 'children'
      );
      if (!childrenTaskNode) {
        childrenTaskNode = treeUtils.getNodeFromCeleryTask(groupTask, 'GROUP');
      }
      currentNode.children.push(childrenTaskNode);
    },
    addChildTask(currentNode, childTask) {
      const childTaskNode = treeUtils.getNodeFromCeleryTask(childTask, 'FLAT_CHILD');
      currentNode.children.push(childTaskNode);
    },
  }
};
</script>

<style lang="scss">
.tree-container {
  display: flex;
  flex-direction: row;
  border: 1px solid #333333;
  border-radius: 5px;
}

.tree-chart-container {
  flex: 1;
}

.node-info-container {
  // width: 40%;
  border-left: 1px solid #333333;
}
</style>
