<template>
  <div class="scraper-tasks-detail-row">
    <loader v-if="apiLoading" />
    <data-table
      ref="scrapingtaskstable"
      :fields="fields"
      :data="scrapingTasks"
      :per-page="pagination.limit"
      :total-pages="pagination.pages"
      :currentPage="pagination.currentPage"
      :api-mode="true"
      :expandableRow="expandableRow"
      table-height="300px"
      @page-selected="loadScrapingTasks"
      v-else
    >
      <template slot="status" slot-scope="props">
        <va-popover
          title="Task ID"
          :message="props.rowData.celeryTaskId"
          placement="top"
        >
          {{ props.rowData.status }}
        </va-popover>
      </template>
      <template slot="createdAt" slot-scope="props">
        <va-popover title="" :message="props.rowData.createdAt" placement="top">
          {{ props.rowData.createdAt | momentDaysAgo }}
        </va-popover>
      </template>
      <template slot="updatedAt" slot-scope="props">
        <va-popover title="" :message="props.rowData.updatedAt" placement="top">
          {{ props.rowData.updatedAt | momentDaysAgo }}
        </va-popover>
      </template>
      <template slot="actions" slot-scope="props">
        <scraping-task-row-action
          :task="props.rowData"
          :isChildrenTreeVisible="isChildrenRowVisbleForRowId(props.rowData.id)"
          @view-task="handleViewChildrenButtonClick"
          @delete="removeTaskById"
        />
      </template>
    </data-table>
  </div>
</template>

<script>
import { fetchScrapingTasks } from '../../../apollo/api/scrapingTasks';
import { SCRAPING_TASKS_TABLE_FIELDS } from '../../../constants/tableFields';
import { momentDaysAgo } from '../../../mixins/filters';
import Loader from '../../Loader';
import DataTable from '../../DataTable';
import ScrapingTaskRowAction from './ScrapingTaskRowAction.vue';
import ScrapingRowChildren from './ScrapingRowChildren';

export default {
  name: 'scraping-tasks-expanded-row',
  components: {
    Loader,
    DataTable,
    ScrapingTaskRowAction,
    ScrapingRowChildren,
  },
  props: {
    rowData: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
    },
    options: {
      type: Object,
    },
  },
  data() {
    return {
      mangaScraperId: null,
      lastScrapedChapter: null,
      lastSuccessfulScrap: null,
      scrapingTasks: [],
      apiLoading: false,
      expandableRow: ScrapingRowChildren,
      pagination: {
        limit: 20,
        currentPage: 1,
        pages: 0,
        total: 0,
      },
    };
  },
  computed: {
    fields() {
      return SCRAPING_TASKS_TABLE_FIELDS;
    },
  },
  filters: {
    momentDaysAgo,
  },
  created() {
    this.mangaScraperId = this.rowData.id;
    this.lastScrapedChapter = this.rowData.scraper.lastScrapedChapter;
    this.lastSuccessfulScrap = this.rowData.scraper.lastSuccessfulScrap;
  },
  async mounted() {
    await this.loadScrapingTasks();
  },
  methods: {
    removeTaskById(taskId) {
      const newScrapingTasks = this.scrapingTasks.filter(
        task => task.id != taskId
      );
      console.log('new tasks ', newScrapingTasks);
      this.scrapingTasks = [...newScrapingTasks];
    },
    async loadScrapingTasks(page = 1) {
      this.apiLoading = true;
      try {
        const { mangaScrapingTasksList } = await fetchScrapingTasks(
          this.mangaScraperId,
          this.pagination.limit,
          page
        );
        this.scrapingTasks = mangaScrapingTasksList.scrapingTasks;
        this.pagination = {
          ...this.pagination,
          currentPage: mangaScrapingTasksList.currentPage,
          pages: mangaScrapingTasksList.pages,
          total: mangaScrapingTasksList.total,
        };
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.apiLoading = false;
    },
    handleViewChildrenButtonClick(rowId) {
      this.$refs.scrapingtaskstable.handleDetailRowToggleById(rowId);
    },
    isChildrenRowVisbleForRowId(rowId) {
      return this.$refs.scrapingtaskstable.isDetailedRowVisible(rowId);
    },
  },
};
</script>

<style lang="scss">
.scraper-tasks-detail-row {
  border: 1px solid #e9e9eb6b;
  border-radius: 5px;
}
</style>
