<template>
  <div class="scraper-row-action">
    <va-toggle
      v-model="mangaScraper.enabled"
      small
      @input="toggleScraperEnable"
    />
    <va-popover
      message="Start scraper now"
      v-if="mangaScraper.enabled && !mangaScraper.running"
    >
      <a @click="scrapMangaNow">
        <va-icon name="fa fa-gears" :size="25" class="action-icon pointer" />
      </a>
    </va-popover>
    <va-popover
      message="Scraper running"
      v-else-if="mangaScraper.enabled && mangaScraper.running"
    >
      <spring-spinner :animation-duration="2200" :size="25" color="green" />
    </va-popover>

    <a @click="showEditMangaScraperModal">
      <va-icon
        name="fa fa-pencil-square-o"
        :size="25"
        class="action-icon pointer"
      />
    </a>
    <a @click="emitMoreInfoEvent">
      <va-icon
        :name="!isDetailedRowVisible ? 'fa fa-eye' : 'fa fa-eye-slash'"
        :size="25"
        class="action-icon pointer"
      />
    </a>
    <a @click="showDeleteMangaScraperModal = true">
      <va-icon name="fa fa-trash-o" :size="25" class="action-icon pointer" />
    </a>

    <va-modal
      v-model="showEditMangaScraperModalFlag"
      okText="Edit scraper"
      :cancelText="$t('modal.cancel')"
      @ok="submitChanges()"
    >
      <div class="flex">
        <div class="row">
          <div class="flex xs12 md12">
            <p class="display-4">
              {{ selectedMangaTitle }} [{{ mangaScraper.scraper.alias }}]
              scraper
            </p>
            <br />
            <div class="row">
              <div class="flex xs6 md6">
                <va-select
                  label="Scraper"
                  placeholder="Select scraper"
                  v-model="selectedScraper"
                  :options="scraperOptions"
                  noClear
                  required
                />
                <va-input
                  class="mb-4"
                  v-model="modifiedMangaScraper.cronjobString"
                  label="Crontab"
                  placeholder="Crontab string"
                  readonly
                />
              </div>
              <div class="flex xs6 md5 offset--md1">
                <va-input
                  label="Site manga ID"
                  placeholder="Site's manga ID"
                  type="text"
                  v-model="modifiedMangaScraper.siteMangaId"
                  required
                />
              </div>
            </div>
            <div class="row">
              <div class="flex xs12 md12">
                <VueCronEditorBuefy
                  v-model="modifiedMangaScraper.cronjobString"
                  :visibleTabs="['daily', 'weekly', 'monthly']"
                />
              </div>
            </div>
            <div class="row">
              <div class="flex xs6 md6">
                <va-toggle
                  label="Enabled"
                  color="primary"
                  v-model="modifiedMangaScraper.enabled"
                  small
                />
              </div>
              <div class="flex xs6 md6" v-if="!mangaScraper.running">
                <va-input
                  label="Scraping index"
                  placeholder="Start scraping from this index"
                  type="text"
                  v-model="scrapingChapterIndex"
                  required
                />
                <va-button
                  :disabled="scrapingChapterIndex < 1"
                  color="success"
                  small
                  outline
                  @click="scrapMangaNow(scrapingChapterIndex)"
                  >Start scraper</va-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </va-modal>
    <va-modal
      v-model="showDeleteMangaScraperModal"
      size="small"
      title="Delete manga scraper"
      :okText="$t('modal.confirm')"
      :cancelText="$t('modal.cancel')"
      @ok="deleteMangaScraperFunctionCall"
    >
      Are you sure you want to {{ mangaScraper.scraper.name }} scraper?
    </va-modal>
  </div>
</template>

<script>
import VueCronEditorBuefy from 'vue-cron-editor-buefy';
import { SpringSpinner } from 'epic-spinners';
import { mapMutations, mapGetters } from 'vuex';
import {
  updateMangaScraper,
  deleteMangaScraper,
  startMangaScraper,
} from '../../apollo/api/mangaScrapers';

export default {
  name: 'scraper-row-action',
  components: { SpringSpinner, VueCronEditorBuefy },
  props: {
    mangaScraper: Object,
    scrapers: Object,
    isDetailedRowVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showEditMangaScraperModalFlag: false,
      showDeleteMangaScraperModal: false,
      modifiedMangaScraper: { ...this.mangaScraper },
      selectedScraper: {
        id: this.mangaScraper.scraper.id,
        text: this.mangaScraper.scraper.alias,
      },
      scraperOptions: [],
      scrapingChapterIndex: -1,
      apiLoading: false,
    };
  },
  computed: {
    ...mapGetters(['selectedMangaTitle']),
  },
  created() {
    for (const scraperId in this.scrapers) {
      this.scraperOptions.push({
        id: this.scrapers[scraperId].id,
        text: this.scrapers[scraperId].alias,
      });
    }
  },
  methods: {
    ...mapMutations(['setBackgroundLoading']),
    showEditMangaScraperModal() {
      this.modifiedMangaScraper = { ...this.mangaScraper };
      this.showEditMangaScraperModalFlag = true;
    },
    emitMoreInfoEvent() {
      this.$emit('moreInfoButtonClick', this.mangaScraper.id);
    },
    async scrapMangaNow(chapterIndexStr) {
      let chapterIndex = -1;
      if (!!chapterIndex) {
        try {
          chapterIndex = parseInt(chapterIndexStr);
          if (chapterIndex < 1) {
            throw new Error('Chapter index must be greater than 0');
          }
        } catch (e) {
          console.log(e);
          return;
        }
      }

      this.apiLoading = true;
      try {
        await startMangaScraper(
          this.mangaScraper.id,
          chapterIndex == -1 ? null : chapterIndex
        );
        this.$emit('update', { ...this.mangaScraper, running: true });
        this.showToast('Manga scraper started', {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }

      if (chapterIndex > 0) {
        // Close the modal automatically if user starts scraper
        this.showEditMangaScraperModalFlag = false;
      }
      this.apiLoading = false;
    },
    async toggleScraperEnable(val) {
      this.apiLoading = true;
      try {
        const { updateMangaScraperRelation } = await updateMangaScraper(
          this.mangaScraper.id,
          {
            scraperId: this.mangaScraper.scraper.id,
            siteMangaId: this.mangaScraper.siteMangaId,
            cronjobString: this.mangaScraper.cronjobString,
            enabled: val,
          }
        );
        this.$emit('update', { ...this.mangaScraper, enabled: val });
        this.showToast('Scraper toggled successfully', {
          position: 'top-right',
          duration: 800,
          fullWidth: false,
        });
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.apiLoading = false;
    },
    async submitChanges() {
      if (this.selectedScraper.id == null) {
        this.showToast('Please select a scraper', {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
        return;
      }
      this.modifiedMangaScraper.scraperId = this.selectedScraper.id;

      if (this.modifiedMangaScraper.siteMangaId == '') {
        this.showToast("Please provide valid site's manga id", {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
        return;
      }

      if (this.modifiedMangaScraper.cronjobString == '') {
        this.showToast('Please provide valid cronjob string', {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
        return;
      }

      this.apiLoading = true;
      try {
        const { updateMangaScraperRelation } = await updateMangaScraper(
          this.mangaScraper.id,
          {
            scraperId: this.modifiedMangaScraper.scraperId,
            siteMangaId: this.modifiedMangaScraper.siteMangaId,
            cronjobString: this.modifiedMangaScraper.cronjobString,
            enabled: this.modifiedMangaScraper.enabled,
          }
        );
        // Tell parent about this new child
        this.$emit('update', updateMangaScraperRelation.mangaScraperRelation);
        this.showToast('Scraper updated successfully', {
          position: 'top-right',
          duration: 800,
          fullWidth: false,
        });
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.apiLoading = false;
    },
    async deleteMangaScraperFunctionCall() {
      this.apiLoading = true;
      try {
        await deleteMangaScraper(this.mangaScraper.id);
        this.$emit('delete', this.mangaScraper);
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.apiLoading = false;
    },
  },
};
</script>

<style>
.scraper-row-action {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
</style>
