import gql from 'graphql-tag';

export const QUERIES = {
  /** Scraping task queries */
  SCRAPING_TASKS: gql`
    query FETCH_SCRAPING_TASKS(
      $mangaScraperId: String
      $limit: Int
      $page: Int
    ) {
      mangaScrapingTasksList(
        mangaScraperId: $mangaScraperId
        limit: $limit
        page: $page
      ) {
        scrapingTasks {
          id
          celeryTaskId
          status
          trigger
          type
          isTaskAbortable
          chaptersFound
          chaptersScraped
          createdAt
          updatedAt
        }
        currentPage
        pages
        total
      }
    }
  `,
  /** Celery task queries */
  CELERY_TASK: gql`
    query FETCH_CELERY_TASK($celeryTaskId: String) {
      celeryTaskItem(celeryTaskId: $celeryTaskId) {
        id
        name
        args
        queue
        status
        progress
        runningSince
        createdAt
        lastModifiedAt
        dateDone
        childTasks
        childGroups
      }
    }
  `,
  CELERY_GROUP: gql`
    query FETCH_CELERY_GROUP($celeryGroupId: String) {
      celeryGroupItem(celeryGroupId: $celeryGroupId) {
        id
        status
        progress
        runningSince
        createdAt
        lastModifiedAt
        dateDone
        childTasks
      }
    }
  `,
};

export const MUTATIONS = {
  ABORT_SCRAPING_TASK: gql`
    mutation ABORT_SCRAPING_TASK($scrapingTaskId: ID!) {
      abortScrapingTask(scrapingTaskId: $scrapingTaskId) {
        response
      }
    }
  `,
  ABORT_CELERY_TASK: gql`
    mutation ABORT_CELERY_TASK($celeryTaskId: String!) {
      abortCeleryTask(celeryTaskId: $celeryTaskId) {
        response
      }
    }
  `
};
